export const RequestHeaderNames = {
  contentType: 'Content-Type',
  authorization: 'Authorization',
  apimSubscriptionKey: 'Ocp-Apim-Subscription-Key',
};

export const ContentTypes = {
  urlEncoded: 'application/x-www-form-urlencoded',
  json: 'application/json',
};

const portalApi = `${process.env.REACT_APP_PORTAL_API_BASE_URL}/api/v1`;

export const Urls = {
  ciamAuthorize: `${process.env.REACT_APP_FORGEROCK_URL}/am/oauth2/authorize`,
  ciamToken: `${process.env.REACT_APP_FORGEROCK_URL}/am/oauth2/access_token`,
  ciamRevokeToken: `${process.env.REACT_APP_FORGEROCK_URL}/am/oauth2/realms/root/realms/alpha/token/revoke`,
  ciamEndSession: `${process.env.REACT_APP_FORGEROCK_URL}/am/oauth2/connect/endSession`,
  ciamSessionTermination: 'https://www.fortum.fi',
  userInfo: `${portalApi}/customer`,
  marketingInfo: `${portalApi}/customer/marketing-info`,
  deliveryPoints: `${portalApi}/delivery-point`,
  invoices: `${portalApi}/invoice`,
  openInvoicesKPI: `${portalApi}/invoice/open-invoices-kpi`,
  invoicePDF: `${portalApi}/invoice/file`,
  customerSupport: `${portalApi}/support`,
  consumption: `${portalApi}/consumption`,
  forecastedCosts: `${portalApi}/forecast/graph`,
  forecastedUsage: `${portalApi}/forecast/table`,
  appSessionTermination: `${portalApi}/customer/logout`,
  ssoLoginPortal: `${process.env.REACT_APP_SSO_LOGIN_PORTAL_BASE_URL}`,
  reports: `${portalApi}/report`,
  reportPDF: `${portalApi}/report/file`,
  enclosurePDF: `${portalApi}/enclosure/file`,
  certificatePDF: `${portalApi}/certificate/file`,
  survey: `${portalApi}/survey`,
  admin: `${portalApi}/admin`,
};

export const HttpStatusCodes = {
  UNAUTHORIZED: 401,
};
